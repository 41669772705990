<template>
  <v-sheet :color="wsLIGHTCARD" class="wsRoundedHalf pa-5 ">

    <ws-link
        class="noUnderline "
        :href="item.external_link || null"
        :to="!item.external_link ? localeLink('news/' + item.alias) : null"
        new-window
    >

      <v-img class="wsRoundedHalf" height="200" :src="item.img" >
        <template v-if="!item.img" >
          <v-sheet class="wsRoundedHalf" height="200" :color="wsBACKGROUND" />
        </template>
      </v-img>

      <v-sheet color="transparent" height="50" class="mt-5">
        <h4 :style="`color : ${wsACCENT}`" class="font-weight-regular hoverUnderline">{{  item.title  }}</h4>
      </v-sheet>

    </ws-link>


    <h5 :style="`color : ${wsDARKLIGHT}`" class="font-weight-regular mt-9">
      {{ PARSE_DATE(item.date_published , false ,true) }}
    </h5>

  </v-sheet>
</template>

<script>
export default {
  name: "blogCard",
  props : {
    item : {
      type : Object ,
      default() { return {} }
    }
  }
}
</script>

<style scoped>

</style>