<template>
  <page-section
      :title="$t('Blog')"
  >

    <v-row class="mt-3">
      <v-col cols="12" md="4" v-for="(item , i) in items" :key="i">
        <blog-card :item="item" />
      </v-col>
    </v-row>

    <div class="d-flex justify-center pt-8">
      <ws-button
          :to="localeLink('news')"
          label="SeeMore"
      />
    </div>

  </page-section>
</template>

<script>
import blogCard from "@/components/pages/homepage_westudy/home/sections/UI/blogCard";
import {mapActions} from "vuex";
export default {
  name: "homeSectionCases",
  components : {
    blogCard
  },
  data() {
    return {
      items : []
    }
  },
  methods : {
    ...mapActions('news', [
          'GET_FEATURED_NEWS_MAIN_PUBLIC'
        ]
    ),
  },
  async mounted() {
    let result = await this.GET_FEATURED_NEWS_MAIN_PUBLIC()
    if ( !result || result === true ) {
      return
    }
    this.items = result
  }
}
</script>

<style scoped>

</style>